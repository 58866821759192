import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { get_active_subscription } from '../api/subscriptionRequests';


const initialStateWithDaysLeft = {
  product_plan: '',
  created_date: '',
  expiry_date: '',
  daysLeft: null, // Optional, based on when it's calculated
};
const initialContextState = {
  activePlan: initialStateWithDaysLeft,
  setActivePlan: () => {
  },
};
export const AccountContext = createContext(initialContextState);


const calculateDaysLeft = (expiryDateString) => {
  const expiryDate = new Date(expiryDateString);
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = expiryDate.getTime() - currentDate.getTime();

  // Convert difference in milliseconds to days
  const daysLeft = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  // Math.ceil will round up to the nearest whole number
  // If there's any part of a day left, it'll be counted as a full day
  return Math.ceil(daysLeft);
};

export const AccountProvider = ({ children, isAuthenticated }) => {


  const [activePlan, setActivePlan] = useState(initialStateWithDaysLeft); // State to track data fetched status
  const [isDataFetched, setIsDataFetched] = useState(false); // State to track data fetched status

  useEffect(() => {
    if (!isAuthenticated) {
      setIsDataFetched(true);
      // Don't fetch data if not authenticated
      return;
    }
    // Ensure values are reset in case of re-fetching
    setIsDataFetched(false);
    console.log("getting subscription")
    get_active_subscription()
      .then((res) => {
        if (res.data.expiry_date) {
          const daysLeft = calculateDaysLeft(res.data.expiry_date); // Utilize your new function here
          setActivePlan({ ...res.data, daysLeft }); // Assuming setActivePlan can now handle daysLeft
        } else {
          const daysLeft = null;
          setActivePlan({ ...res.data, daysLeft });
        }
        setIsDataFetched(true); // Set fetched status to true when data is loaded and processed
      })
      .catch((err) => {
        console.log(err);
        setIsDataFetched(true); // Ensure children can still be rendered in case of an error
      });
  }, [isAuthenticated]);

  return (
    <AccountContext.Provider value={{ activePlan, setActivePlan }}>
      {isDataFetched ? children : null} {/* Only render children when data has been fetched */}
    </AccountContext.Provider>
  );
};
// Hook to use auth context
export const useAccount = () => useContext(AccountContext);