import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getPublicUrl } from './PublicUrl';


const PublicRoute = ({ component: Component, layout: Layout, ...rest }) => {
    const location = useLocation();

  return (
    <Route {...rest} render={(props) => (
      <Layout>
         <Helmet>
          <link rel="canonical" href={getPublicUrl(location.pathname)} />
           <meta property="og:url" content={getPublicUrl(location.pathname)}/>
           <meta name="twitter:url" content={getPublicUrl(location.pathname)} />
      </Helmet>
        <Component {...props} />
      </Layout>
    )} />
  );
};

export default PublicRoute;