export const AuthStates = {
  STATE_LOGIN: 'LOGIN',
  STATE_SIGNUP: 'SIGNUP',
  STATE_RESET_PASSWORD: 'RESET_PASSWORD',
  STATE_FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  STATE_PASSWORD_RESET_LINK_SENT: 'PASSWORD_RESET_LINK_SENT',
  STATE_VERIFY_EMAIL: 'VERIFY_EMAIL',
  STATE_BACK_FROM_EMAIL_VALIDATION: 'LINK_EMAIL_VALIDATION',
  STATE_PASSWORD_CHANGED: 'PASSWORD_CHANGED'
};
