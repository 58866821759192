import React, { useState } from 'react';
import { Modal, Form, Button, Alert, Row, Col } from 'react-bootstrap';
import { submit_feedback, submit_public_feedback } from '../../../api/userFeedbackRequests';

const ContactUsModal = ({ show, handleClose, options, defaultReason, userLoggedIn }) => {
  const [selectedOption, setSelectedOption] = useState(options.find(option => option.value === defaultReason) || {});
  const [contactDetails, setContactDetails] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [feedbackSuccess, setFeedbackSuccess] = useState(false);
  const [email, setEmail] = useState('');

  const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleModalSubmit = async () => {
    try {
      if (!userLoggedIn) {
        if (!email || !isValidEmail(email)) {
          alert('Please enter a valid email.');
          return;
        }
        // You may want to handle the anonymous user scenario differently,
        // e.g., requiring email verification or providing a different feedback route.
      }
      // Check if user is logged in, then call the appropriate feedback submission function
      if (userLoggedIn) {
        await submit_feedback(selectedOption.value, contactDetails);
      } else {
        if (!email) {
          alert('Email is required.');
          return;
        }
        // Assuming submit_public_feedback function requires email and contactDetails.
        await submit_public_feedback(email, selectedOption.value, contactDetails);
      }

      setFeedbackSubmitted(true);
      setFeedbackSuccess(true);

      setTimeout(() => {
        handleClose();
        setFeedbackSubmitted(false); // Reset for next use
        setEmail(''); // Reset email field
      }, 700);

    } catch (error) {
      console.error('Failed to submit feedback:', error);
      setFeedbackSubmitted(true);
      setFeedbackSuccess(false);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const option = options.find(option => option.value === value);
    setSelectedOption(option);
  };

  return (
    <Modal show={show} onHide={() => {
      handleClose();
      setFeedbackSubmitted(false); // Reset states
    }} scrollable={false}>
      <Modal.Header closeButton>
        <Modal.Title>Contact Us</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ overflowY: 'auto' }}>

        {feedbackSubmitted && feedbackSuccess && (
          <Alert variant='success'>
            Thank you for your feedback regarding "{selectedOption.label}". We will review it shortly and take
            appropriate action.
          </Alert>
        )}
        {feedbackSubmitted && !feedbackSuccess && (
          <Alert variant='danger'>
            There was an issue submitting your feedback. Please try again later.
          </Alert>
        )}

        {!feedbackSubmitted && (
          <Form>
            {!userLoggedIn && (
              <Form.Group controlId='contactEmail'>
                <Form.Label>Email (required)</Form.Label>
                <Form.Control type='email' placeholder='Enter your email' value={email}
                              onChange={(e) => setEmail(e.target.value)} required />
              </Form.Group>
            )}
            <Form.Group controlId='contactReason'>
              <Form.Label>Reason for Contact</Form.Label>
              <Form.Control as='select' value={selectedOption.value || ''} onChange={handleChange}>
                {options.map((option, index) => (
                  <option key={index} value={option.value}>{option.label}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId='contactDetails'>
              <Form.Label>Details</Form.Label>
              <Form.Control as='textarea' rows={3} value={contactDetails}
                            onChange={(e) => setContactDetails(e.target.value)} />
            </Form.Group>
          </Form>
        )}
      </Modal.Body>

      {!feedbackSubmitted && (
        <Modal.Footer className='d-flex justify-content-between'>
          <Row className='w-100'>
            <Col className='col-md-auto px-0'>
              <Button variant='secondary' onClick={() => {
                handleClose();
                setFeedbackSubmitted(false); // Reset on button click
              }}>Cancel</Button>
            </Col>
            <Col className='d-flex justify-content-end px-0'>
              <Button variant='primary' onClick={handleModalSubmit}>Submit</Button>
            </Col>
          </Row>
        </Modal.Footer>
      )}
      {feedbackSubmitted && feedbackSuccess && (
        <Modal.Footer className='d-flex justify-content-center'>
          <Button variant='success' onClick={() => {
            handleClose();
            setFeedbackSubmitted(false); // Reset for next open
          }}>Close</Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ContactUsModal;