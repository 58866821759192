/* eslint camelcase: 0 */

import axios from 'axios';

import {base_url,tokenConfig } from './common';

const prefix = base_url+'user/'
export function validate_token(token) {
    return axios.post(prefix+'is_token_valid', {
        token,
    });
}
export function refresh_initial_token(token) {
    return axios.post(prefix+'refresh_initial_token', {
        token,
    });
}


export function create_user(email, password, company, send_email_verification) {
    return axios.post(prefix+'create_user', {
        email,
        password,
        company,
        send_email_verification
    });
}

export function get_token(email, password) {
    return axios.post(prefix+'get_token', {
        email,
        password,
    });
}


export function change_password(token,password){
    return axios.post(prefix+'change_password', {
        token,
        password,
    });
}

export function get_password_reset_link(email){
    return axios.post(prefix+'send_reset_password_link', {
        email
        
    });
}

export function get_email_verify_link(email){
    return axios.post(prefix+'send_email_verification_link', {
        email
        
    });
}



export function validate_email(token){
    return axios.post(prefix+'validate_email', {
        token,
    });
    }


    export function get_email_using_token(token){
        return axios.post(prefix+'get_email_using_token', {
            token,
        });
    }
    

    export function api_get_list_of_users(){

        var url = prefix+'get_all_users'

    return axios.get(url , tokenConfig());
    }