import React, { createContext, useContext, useReducer, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validate_email, get_token, refresh_initial_token, create_user, validate_token } from '../api/authRequests';
import { AuthStates } from '../components/auth/elements/authConsts'; // Ensure these are implemented

// Initial state for the context
const initialState = {
  user: null,
  authState: AuthStates.STATE_SIGNUP, // LOGIN, SIGNUP, etc.
  isLoading: false,
  errors: null,
};

export const AuthContext = createContext(initialState);

// Reducer to handle state updates


// AuthProvider component
export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [authState, setAuthState] = useState(AuthStates.STATE_LOGIN); // Default state
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);


  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setIsAuthenticated(false);
          setIsLoading(false);
          return;
        }
        // Assuming validate_token function is adapted to accept the token directly
        // and properly handles the promise resolution with whether the token is valid.
        const response = await validate_token(token);
        if (response.status === 200 && response.data.token_is_valid) {  // Adjust based on the actual response structure
          console.log('checkAuth setIsAuthenticated(true)');
          setIsAuthenticated(true);
        } else {
          localStorage.removeItem('token');
          setIsAuthenticated(false);
          setIsLoading(false);
        }
      } catch (error) {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
        setIsLoading(false);
      }
    };

    checkAuth();
  }, []);


  // Authenticate user
  const authenticateUser = async (formData) => {

    try {
      let response = await get_token(formData.email, formData.password);
      console.log('getting token');
      if (response && response.data) {
        window.Cue.setIdentity( {
          uid: response.data.user_id,
          properties: {
            displayName: response.data.displayName,
            email: response.data.email,
            companyName: response.data.companyName,
          }});

        localStorage.setItem('token', response.data.token);
        localStorage.setItem('task_id', response.data.task.id);
        console.log('authenticateUser setIsAuthenticated(true)');
        setIsAuthenticated(true);
        setIsLoading(false);
        return response;
      }
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Logout function
  const logout = (callback) => {

    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setIsLoading(false);
    if (callback) {
      callback();
    }
    // Redirect to login page
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        authState,
        setAuthState,
        setIsAuthenticated,
        isLoading,
        errors,
        authenticateUser,
        isAuthenticated,
        logout,
      }}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
};

// Hook to use auth context
export const useAuth = () => useContext(AuthContext);