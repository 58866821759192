import React, { createContext, useState, useEffect, ReactNode } from 'react';

import { categories, Category } from '../shared/components/bars/side-bar/components/SideBarCategories';

import { MdAutoAwesome, MdBook, MdOutlineTimeline } from 'react-icons/md';
import { get_all_ai_reports } from '../api/reportsRequests';
import { get_user_preference_left_menu } from '../api/userPreferenceRequests';


interface Report {
  name: string;
  id: string;
}

interface Board {
  title: string;
  id: number;
}

interface interfaceUserBoard {
  title: string;
  params: {
    id: number;
  };
}

interface NavigationProviderProps {
  children: ReactNode;
}



type NavigationContextType = {
  categoryList: Category[];
  addSubcategory: (parentId: string, newSubcategory: Category) => void;
};

export const NavigationContext = createContext<NavigationContextType>({
  categoryList: [],
  addSubcategory: () => {},
});
export const NavigationProvider: React.FC<NavigationProviderProps & { isAuthenticated: boolean }> = ({
                                                                                                       children,
                                                                                                       isAuthenticated,
                                                                                                     }) => {


  const [categoryList, setCategoryList] = useState(categories);
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false); // State to track data fetched status

  const findAndAddChildren = (nodes: Category[], targetID: string, children: Category[]): Category[] => {
    return nodes.map(node => {
      if (node.id === targetID) {
        if(!node.children) {
          node.children = children;
        }
        else{
          node.children = node.children.concat(children);
        }
        return node;
      } else if (node.children) {
        node.children = findAndAddChildren(node.children, targetID, children);
        return node;
      } else {
        return node;
      }
    });
  };

  const addSubcategory = (parentId: string, newSubcategory: Category) => {
  setCategoryList((currentCategories) => {
    const updatedCategories = findAndAddChildren(currentCategories, parentId, [newSubcategory]);
    return updatedCategories;
  });
};

  useEffect(() => {
    if (!isAuthenticated) {
      setIsDataFetched(true);
      // Don't fetch data if not authenticated
      return;
    }
    // Ensure values are reset in case of re-fetching
    setIsDataFetched(false);
    console.log("fetching preferences")
    get_user_preference_left_menu()
      .then((res: { data: { ai_reports: Report[], user_boards: Board[] } }) => {
        const reports = res.data.ai_reports.map(report => ({
          name: report.name,
          params: { report_id: report.id },
          icon: <MdAutoAwesome />,
        }));
        const userBoards = res.data.user_boards.map(board => ({
          name: board.title,
          params: { id: board.id.toString() },
          icon: <MdOutlineTimeline />,
        }));
        let updatedCategories = findAndAddChildren(categoryList, 'ai-generated', reports);
        updatedCategories = findAndAddChildren(updatedCategories, 'roadmaps', userBoards);
        setCategoryList(updatedCategories);
        setIsDataFetched(true); // Set fetched status to true when data is loaded and processed
      })
      .catch(err => {
        console.log(err);
        setIsDataFetched(true); // Ensure children can still be rendered in case of an error
      });
  }, [isAuthenticated]);

  return (
  <NavigationContext.Provider value={{ categoryList, addSubcategory }}>
    {isDataFetched ? children : null} {/* Only render children when data has been fetched */}
  </NavigationContext.Provider>
  );
};