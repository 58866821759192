import React from 'react';
import { Col } from 'react-bootstrap';
import { Tab, Tabs } from 'react-bootstrap';
import styled from 'styled-components';
// Images
import CustomerExperience from '../../../assets/img/customers/happy_customer.jpg';
import CustomerFeedbackSegmentation from '../../../assets/img/homepage/teams/customer_feedback_segmentation.png';
import ChurnRateReduction from '../../../assets/img/public/homepage/teams/churn_rate_reduction.jpg';
// import EmbeddingCustomerInsightsImage from '../../../assets/img/homepage/use-cases/Embedding_Customer_Insights_into_Product_Strategy.png';
import LeveragingAnalyticsImage
  from '../../../assets/img/public/homepage/teams/Leveraging_Analytics_to_Drive_Product_Innovation.jpg';
import CustomerProductManager from '../../../assets/img/customers/face3.png';
import ReduceChurn from '../../../assets/img/public/homepage/teams/Understanding_Churn_and_Its_Impact_on_Business.jpg';
import ProactivePreventChurn
  from '../../../assets/img/public/homepage/teams/Proactive_Strategies_to_Prevent_Customer_Churn.jpg';
import LeveragingFeedbackChurn from '../../../assets/img/public/homepage/teams/Leveraging_Feedback_to_Reduce_Churn.jpg';
import StreamliningOperationsImage
  from '../../../assets/img/public/homepage/teams/The_Significance_of_Streamlining_Operations_for_Efficiency.jpg';
import ProcessEfficiencyImage
  from '../../../assets/img/public/homepage/teams/Innovative_Approaches_to_Enhance_Process_Efficiency.jpg';
import OperationalEfficiencyBenefitsImage
  from '../../../assets/img/public/homepage/teams/Key_Benefits_of_Improving_Operational_Efficiency.jpg';
import MarketLeadershipImage
  from '../../../assets/img/public/homepage/teams/Harnessing_Customer_Insights_for_Market_Leadership.jpg';
import DifferentiationImage
  from '../../../assets/img/public/homepage/teams/Differentiation_Through_Personalization.jpg';
import SustainingGrowthImage
  from '../../../assets/img/public/homepage/teams/Sustaining_Growth_with_Continuous_Improvement.jpg';
import { useMediaQuery } from 'react-responsive';

const FeatureItem = styled.div`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  align-items: center;
  text-align: left;
  padding: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 10px;
  }
`;

const FeatureText = styled.h4`
  margin-top: 10px;
  font-size: 1.1rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const StyledTabs = styled(Tabs)`
  .nav-item {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: ${props => props.maxWidth || '300px'};
    @media (max-width: 768px) {
      max-width: 100%;
    }

    .nav-link {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      white-space: normal !important;
      width: 100%;
      color: #aaa !important;

      &.active {
        color: black !important;
        background-color: #fff !important;
      }
    }
  }
`;

const DetailedParagraph = styled.p`
  max-width: 360px;
  text-align: left;
  margin: 10px 20px;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 0.9rem;
    max-width: 100%;
  }
`;

const RoundedImage = styled.img`
  max-width: 100%;
  border-radius: 8px;
  margin: 10px 20px;
  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const SmallerRoundedImage = styled(RoundedImage)`
  max-width: 80%;
`;

const TeamTabs = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <StyledTabs defaultActiveKey='productManagers' id='team-benefit-tabs' className='mb-3'
                maxWidth={isMobile ? '100%' : '200px'}>
      <Tab eventKey='productManagers' title='Product Managers'>
        <FeatureItem>
          <Col md={4}>
            <FeatureText>Customer Insights into Product Strategy</FeatureText>
            <DetailedParagraph>
              {isMobile ? 'Integrate feedback into roadmaps.' : 'Incorporate customer feedback into product roadmaps for better alignment with market needs.'}
            </DetailedParagraph>
          </Col>
          <Col md={4}>
            <SmallerRoundedImage src={CustomerProductManager} alt='Embedding Customer Insights' />
          </Col>
        </FeatureItem>
        <FeatureItem>
          <Col md={4}>
            <SmallerRoundedImage src={LeveragingAnalyticsImage} alt='Leveraging Analytics' />
          </Col>
          <Col md={4}>
            <FeatureText>Drive Innovation with Analytics</FeatureText>
            <DetailedParagraph>
              {isMobile ? 'Use analytics to improve.' : 'Utilize analytics to translate customer data into strategic product improvements.'}
            </DetailedParagraph>
          </Col>
        </FeatureItem>
      </Tab>
      <Tab eventKey='customerExperienceManagers' title='Customer Experience Managers'>
        <FeatureItem>
          <Col md={4}>
            <FeatureText>Engage and Analyze Customer Feedback</FeatureText>
            <DetailedParagraph>
              {isMobile ? 'Boost satisfaction through analysis.' : 'Analyze customer feedback to enhance satisfaction and drive innovation.'}
            </DetailedParagraph>
          </Col>
          <Col md={4}>
            <RoundedImage src={CustomerExperience} alt='Customer Experience' />
          </Col>
        </FeatureItem>
        <FeatureItem>
          <Col md={4}>
            <RoundedImage src={CustomerFeedbackSegmentation} alt='Customer Feedback Segmentation' />
          </Col>
          <Col md={4}>
            <FeatureText>Real-Time Feedback</FeatureText>
            <DetailedParagraph>
              {isMobile ? 'Monitor to enhance experience.' : 'Use real-time analytics to quickly enhance customer experience.'}
            </DetailedParagraph>
          </Col>
        </FeatureItem>
        <FeatureItem>
          <Col md={4}>
            <FeatureText>Prevent Churn</FeatureText>
            <DetailedParagraph>
              {isMobile ? 'Address issues proactively.' : 'Monitor feedback signals to proactively address issues and prevent churn.'}
            </DetailedParagraph>
          </Col>
          <Col md={4}>
            <RoundedImage src={ChurnRateReduction} alt='Churn Rate Reduction' />
          </Col>
        </FeatureItem>
      </Tab>

      <Tab eventKey='uxResearchers' title='UX Researchers'>
        <FeatureItem>
          <Col md={4}>
            <FeatureText>Uncover User Insights</FeatureText>
            <DetailedParagraph>
              {isMobile ? 'Discover behaviors.' : 'Research user behaviors to ensure design meets needs.'}
            </DetailedParagraph>
          </Col>
          <Col md={4}>
            <RoundedImage src={ReduceChurn} alt='Understanding Churn' />
          </Col>
        </FeatureItem>
        <FeatureItem>
          <Col md={4}>
            <RoundedImage src={ProactivePreventChurn} alt='Proactive Strategies to Prevent Customer Churn' />
          </Col>
          <Col md={4}>
            <FeatureText>Innovative Usability Testing</FeatureText>
            <DetailedParagraph>
              {isMobile ? 'Refine with testing.' : 'Refine product interactions through usability testing.'}
            </DetailedParagraph>
          </Col>
        </FeatureItem>
        <FeatureItem>
          <Col md={4}>
            <FeatureText>Continuous Feedback</FeatureText>
            <DetailedParagraph>
              {isMobile ? 'Gather feedback consistently.' : 'Engage users to gather ongoing feedback for continuous improvement.'}
            </DetailedParagraph>
          </Col>
          <Col md={4}>
            <RoundedImage src={LeveragingFeedbackChurn} alt='Leveraging Feedback to Reduce Churn' />
          </Col>
        </FeatureItem>
      </Tab>
      <Tab eventKey='productOperations' title='Product Operations'>
        <FeatureItem>
          <Col md={4}>
            <FeatureText>Operational Efficiency</FeatureText>
            <DetailedParagraph>
              {isMobile ? 'Boost productivity.' : 'Optimize processes to boost productivity and reduce costs.'}
            </DetailedParagraph>
          </Col>
          <Col md={4}>
            <RoundedImage src={StreamliningOperationsImage} alt='Streamlining Operations' />
          </Col>
        </FeatureItem>
        <FeatureItem>
          <Col md={4}>
            <RoundedImage src={ProcessEfficiencyImage} alt='Enhance Process Efficiency' />
          </Col>
          <Col md={4}>
            <FeatureText>Innovative Improvements</FeatureText>
            <DetailedParagraph>
              {isMobile ? 'Enhance efficiency.' : 'Implement innovative approaches to enhance process efficiency.'}
            </DetailedParagraph>
          </Col>
        </FeatureItem>
        <FeatureItem>
          <Col md={4}>
            <FeatureText>Efficiency Benefits</FeatureText>
            <DetailedParagraph>
              {isMobile ? 'Reduce costs.' : 'Achieve reduced costs and improved customer experience with operational efficiency.'}
            </DetailedParagraph>
          </Col>
          <Col md={4}>
            <RoundedImage src={OperationalEfficiencyBenefitsImage} alt='Operational Efficiency Benefits' />
          </Col>
        </FeatureItem>
      </Tab>
      <Tab eventKey='salesCustomerSuccess' title='Sales & Customer Success'>
        <FeatureItem>
          <Col md={4}>
            <FeatureText>Market Leadership</FeatureText>
            <DetailedParagraph>
              {isMobile ? 'Stay ahead with insights.' : 'Use customer insights to stay ahead and deliver unmatched value.'}
            </DetailedParagraph>
          </Col>
          <Col md={4}>
            <RoundedImage src={MarketLeadershipImage} alt='Market Leadership' />
          </Col>
        </FeatureItem>
        <FeatureItem>
          <Col md={4}>
            <RoundedImage src={DifferentiationImage} alt='Differentiation Through Personalization' />
          </Col>
          <Col md={4}>
            <FeatureText>Personalization</FeatureText>
            <DetailedParagraph>
              {isMobile ? 'Tailor services.' : 'Use feedback to personalize your services and differentiate in the market.'}
            </DetailedParagraph>
          </Col>
        </FeatureItem>
        <FeatureItem>
          <Col md={4}>
            <FeatureText>Sustaining Growth</FeatureText>
            <DetailedParagraph>
              {isMobile ? 'Improve continuously.' : 'Continuously improve based on feedback to sustain growth.'}
            </DetailedParagraph>
          </Col>
          <Col md={4}>
            <RoundedImage src={SustainingGrowthImage} alt='Sustaining Growth with Continuous Improvement' />
          </Col>
        </FeatureItem>
      </Tab>
    </StyledTabs>
  );
};

export default TeamTabs;