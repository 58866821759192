import React, { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import styled, { css } from 'styled-components';

const DropdownContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: ${({ hasSpecialContent }) => (hasSpecialContent ? '650px' : 'auto')};
  max-height: 400px;
  overflow-y: auto;
  padding: 1rem;

  @media (max-width: 992px) {
    flex-direction: column;
    width: auto; /* Adjusted to fit the content on small screens */
    max-height: 300px; /* Smaller modal size for easier navigation */
    padding: 1rem 0.5rem; /* Adjust padding to make it more compact */
  }
`;

const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  overflow-x: auto;
  min-width: 250px;
  padding: 0 10px 0 0;

  @media (max-width: 992px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

const SpecialContentContainer = styled.div`
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  @media (max-width: 992px) {
    flex: 1;
    width: 100%;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  padding: 0 10px;

  @media (max-width: 992px) {
    width: auto; /* Adjusted to fit the content on small screens */
    padding: 0;
  }
`;

const DropdownMenu = ({ title, categories }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const hasSpecialContent = categories.some(category => category.embedVideo || category.footerLink);

  const renderSpecialContent = (category) => (
    <>
      {category.embedVideo && (
        <iframe
          src={category.embedVideo.url}
          title={category.embedVideo.title}
          width="100%"
          height="200"
          frameBorder="0"
          allowFullScreen
          style={{ marginBottom: '10px' }}
        ></iframe>
      )}
      {category.footerLink && (
        <NavDropdown.Item href={category.footerLink.href}>
          {category.footerLink.text}
        </NavDropdown.Item>
      )}
    </>
  );

  // Determine if the user is on a large screen or small screen
  const isLargeScreen = window.innerWidth > 992;

  const handleMouseEnter = () => {
    if (isLargeScreen) {
      setShowDropdown(true);
    }
  };

  const handleMouseLeave = () => {
    if (isLargeScreen) {
      setShowDropdown(false);
    }
  };

  const handleClick = () => {
    if (!isLargeScreen) {
      setShowDropdown(!showDropdown);
    }
  };

  return (
    <NavDropdown
      title={<span className="nav-dropdown-title">{title}</span>}
      id="basic-nav-dropdown"
      show={showDropdown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <DropdownContainer hasSpecialContent={hasSpecialContent}>
        <CategoriesContainer>
          {categories.map((category, index) => (
            <Column key={index}>
              <NavDropdown.ItemText className="nav-dropdown-category-title">
                {category.title}
              </NavDropdown.ItemText>
              {category.items.map((item, itemIndex) => (
                <NavDropdown.Item key={itemIndex} href={item.href} className="nav-dropdown-item">
                  {item.text}
                </NavDropdown.Item>
              ))}
            </Column>
          ))}
        </CategoriesContainer>
        {hasSpecialContent && (
          <SpecialContentContainer>
            {categories.filter(category => category.embedVideo || category.footerLink).map(renderSpecialContent)}
          </SpecialContentContainer>
        )}
      </DropdownContainer>
    </NavDropdown>
  );
};

export default DropdownMenu;