import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import DropdownMenu from './DropdownMenu';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import logo from '../../../assets/img/logo/logoCc.png';
import { platformCategories, solutionsCategories, companyCategories } from '../../constants/homepage-categories';

const FreePlanButton = styled.button`
  background-color: #0071e1;
  color: white;
  border: 1px solid #0071e1;
  &:hover {
    background-color: #005cbf;
    border-color: #005cbf;
  }
  border-radius: 7px;
  margin-left: 20px;
  padding: 5px 10px;
  @media (max-width: 992px) {
    margin: 10px 0;
  }
`;

function PublicNavbar() {
  const history = useHistory();

  const handleFreePlanClick = () => {
    history.push('/signup');
  };

  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img
            src={logo}
            height="60"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <DropdownMenu title="Platform" categories={platformCategories} />
            <DropdownMenu title="Solutions" categories={solutionsCategories} />
            <DropdownMenu title="Company" categories={companyCategories} />
            <Nav.Link href="/pricing" className="nav-dropdown-title">
              Pricing
            </Nav.Link>
            <Nav.Link href="/login" className="nav-dropdown-title">
              Login
            </Nav.Link>
            <FreePlanButton onClick={handleFreePlanClick}>
              Start free plan
            </FreePlanButton>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default PublicNavbar;