/* eslint camelcase: 0 */

import axios from 'axios';
import { base_url, tokenConfig } from './common';


export function create_paypal_subscription(plan, payment_terms, quantity) {
  return axios.post(base_url + 'subscription/paypal/create-subscription', {
    'plan': plan,
    'payment_terms': payment_terms,
    'quantity': quantity,
  }, tokenConfig({ 'Content-Type': 'application/json' }));
}

export function refresh_subscription(subscription_id){
    return axios.get(base_url+'subscription/paypal/refresh-subscription?subscription_id='+subscription_id, tokenConfig());
}

export function get_active_subscription(){
    return axios.get(base_url+'subscription/get-active-subscription-light', tokenConfig());
}

