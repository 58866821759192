import React, { Suspense } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import screenRecordingMp4 from '../../assets/img/homepage/screen_record/record_example_shorter.mp4';
import ZendeskLogo from '../../assets/img/companies/logos/zendesk.png';
import SalesforceLogo from '../../assets/img/companies/logos/salesforce.png';
import IntercomLogo from '../../assets/img/companies/logos/intercom.png';
import SampleSurveyResult from '../../assets/img/reporting/sample_survey_result.png';
import styled from 'styled-components';
import { FaChartLine, FaEye } from 'react-icons/fa';
import { Tab, Tabs } from 'react-bootstrap';
import NPSSurveyAnalysisLight from './components/platform/NPSSurveyAnalysisLight';
// import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
// import { PieChart, Pie, Cell } from 'recharts';
// import { Legend, LabelList } from 'recharts';
// import { Chrono } from 'react-chrono';
import roadmapImage from '../../assets/img/homepage/center/board_planning3.png';
import TeamTabs from './teams-tabs/TeamsTabs';
import { Helmet } from 'react-helmet';
// import LazyLoad from 'react-lazyload';
// import { isMobileScreen } from '../../shared/components/layouts/utils/detectScreenSize';

const pieChartTopics = process.env.PUBLIC_URL + '/assets/img/homepage/pie_chart_topics.png';
const roiActions = process.env.PUBLIC_URL + '/assets/img/homepage/roi_actions.png';

const data = [
  {
    title: 'Collect Feedback',
    cardTitle: 'Step 1: Collect Feedback',
    cardDetailedText: 'Start by gathering customer feedback through various channels.',
  },
  {
    title: 'Record Sessions',
    cardTitle: 'Step 2: Record Sessions',
    cardDetailedText: 'Capture user interactions to uncover usability issues and improve UX.',
  },
  {
    title: 'Analyze Data',
    cardTitle: 'Step 3: Analyze Data',
    cardDetailedText: 'Watch session reply and Use AI-driven insights to identify patterns and actionable items.',
  },
  {
    title: 'Build Roadmap',
    cardTitle: 'Step 4: Build Roadmap',
    cardDetailedText: 'Create a prioritized action plan based on session insights.',
  },
  {
    title: 'Implement Actions',
    cardTitle: 'Step 5: Implement Actions',
    cardDetailedText: 'Execute the highest-impact changes first for maximum ROI.',
  },
  {
    title: 'Monitor Improvements',
    cardTitle: 'Step 6: Monitor Improvements',
    cardDetailedText: 'Continuously track the impact of changes and refine strategies.',
  },
];

const StepsText = ({ steps }) => (
  <>
    {steps.map((step, index) => (
      <div key={index} style={{ marginBottom: '20px' }}>
        <FeatureText>{step.title}</FeatureText>
        <DetailedParagraph>{step.cardDetailedText}</DetailedParagraph>
      </div>
    ))}
  </>
);

const issuesData = [
  { name: 'Loading Time', value: 400 },
  { name: 'Layout Issues', value: 300 },
  { name: 'Non-responsive Links', value: 200 },
  { name: 'Content Errors', value: 300 },
];

const improvementData = [
  { description: 'NPS Improvement', value: 3, name: 'Optimize Login Speed' },
  { description: 'CSAT Increase', value: 2, name: 'Fix Broken Links' },
  { description: 'Sentiment Boost', value: 5, name: 'Improve Content Accuracy' },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Section = styled.div`
  padding: 60px 10%;
  text-align: center;
`;

const Description = styled.p`
  color: #666;
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
`;
const ImageCard = styled.div`
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin: 0;
  max-width: 150px;
  padding: 0;
  @media (max-width: 1418px) {
    max-width: 250px;
    margin: 10px 0;
    width: 100%;
    max-width: 100%;
  }
`;
const StyledTabs = styled(Tabs)`
  .nav-item {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: ${props => props.maxWidth || '300px'};

    .nav-link {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      white-space: normal !important;
      width: 100%;
      color: #aaa !important;

      &.active {
        color: black !important;
        background-color: #fff !important;
      }
    }

    .nav-link {
      color: #aaa !important;

      &.active {
        color: black !important;
        background-color: #fff !important;
      }
    }
`;

const Title = styled.h2`
  margin-bottom: 30px;
`;

const StepDescription = styled.p`
  font-size: 16px;
  margin: 0 0 20px 0;
  max-width: 400px;
  @media (max-width: 768px) {
    max-width: 95%;
  }
  font-weight: 900;
`;

const RoundedImage = styled.img`
  border-radius: 8px;
  width: 100%;
  height: auto;
`;

const DetailedParagraph = styled.p`
  max-width: 400px;
  margin: 20px auto;
  @media (max-width: 768px) {
    max-width: 95%;
  }
`;

const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
`;

const FeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-10px);
  }

  svg {
    margin-bottom: 15px;
    font-size: 48px;
    color: #007bff;
  }
`;

const FeatureText = styled(DetailedParagraph)`
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
`;

const FeaturesSection = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
`;

const IntegrationsContainer = styled.div`
  justify-content: space-around;
  margin-bottom: 20px;
`;

const PlatformLogo = styled.img`
  width: 40px;
  height: auto;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
`;

const jsonLd = {
  '@context': 'http://schema.org',
  '@type': 'WebPage',
  'name': 'Home Page',
  'description': 'Welcome to our home page where you can find various insights and features about strategic product roadmap using session recording and feedback analysis.',
  'publisher': {
    '@type': 'Organization',
    'name': 'ClientCues',
    'logo': {
      '@type': 'ImageObject',
      'url': 'https://clientcues.com/logo/logoCc.png',
    },
  },
};

class HomePage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Helmet>
          <script type='application/ld+json'>
            {JSON.stringify(jsonLd)}
          </script>
        </Helmet>


        <NPSSurveyAnalysisLight />
        <FeaturesSection>
          <Section>
            <Row style={{ marginTop: '40px' }}>
              <Col md={12}>
                <FeatureText>Improve with ClientCues</FeatureText>
                <DetailedParagraph>Follow the steps below to enhance your user experience and KPIs.</DetailedParagraph>
                <StepsText steps={data} />
                {/*{isMobileScreen() ? (*/}
                {/*    <StepsText steps={data} />*/}
                {/*  ) : (*/}
                {/*    <Suspense fallback={<div>Loading...</div>}>*/}
                {/*      <Chrono*/}
                {/*        items={data}*/}
                {/*        mode="VERTICAL_ALTERNATING"*/}
                {/*        slideShow={true}*/}
                {/*        slideItemDuration={4500}*/}
                {/*        showNavigation={false}*/}
                {/*        hideControls={true}*/}
                {/*        useReadMore={false}*/}
                {/*        scrollable={false}*/}
                {/*        cardHeight={150}*/}
                {/*        disableToolbar={true}*/}
                {/*      />*/}
                {/*    </Suspense>*/}
                {/*  )}*/}
              </Col>
            </Row>
          </Section>
          <Section style={{ marginTop: '20px' }}>
            <Row>
              <Col className='d-flex align-items-stretch'>
                <CellContainer>
                  <FaChartLine size={50} style={{ alignSelf: 'center', marginBottom: '20px' }} />
                  <StepDescription>Granular Insights</StepDescription>
                  <DetailedParagraph>Get detailed reports for quick decision-making and
                    implementation.</DetailedParagraph>
                </CellContainer>
              </Col>
              <Col className='d-flex align-items-stretch'>
                <CellContainer>
                  <FaEye size={50} style={{ alignSelf: 'center', marginBottom: '20px' }} />
                  <StepDescription>Visual Proof</StepDescription>
                  <DetailedParagraph>Visualize data with ease and share insights with stakeholders.</DetailedParagraph>
                </CellContainer>
              </Col>
            </Row>
          </Section>
          <Section>
            <Title>Empower Your Teams</Title>
            <TeamTabs />
          </Section>
          <Description style={{ margin: 0 }}>
            Understand and enhance user interactions with AI-powered session replays.
            <span style={{ color: 'rgb(123, 1, 247)', marginTop: '3px' }}>Powered by AI</span>
          </Description>
        </FeaturesSection>
      </>
    );
  }
}

export default withRouter(HomePage);