import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import 'filepond/dist/filepond.min.css';
// import './styles/reduction.scss';
import GAListener from './context/GAListener';
import RouteTable from './shared/components/custom-routes/RouteTable';
import { NavigationProvider } from './context/NavigationContext';
import { AccountProvider } from './context/AccountContext';
import { useAuth } from './context/AuthContext';
// import { record } from 'rrweb';
// import ScreenRecorderExt from './extensions/ScreenRecorder';
const loadMinimalCss = () => import('./styles/minimal-reduction.scss');

// Function to load full CSS
const loadFullCss = () => import('./styles/reduction.scss');

function App() {
  const { isAuthenticated } = useAuth();

  // useEffect(() => {
  //   if (!window._clientcues) {
  //     window._clientcues = {
  //       INACTIVITY_THRESHOLD: 30 * 60 * 1000, // 30 minutes
  //       envPublicUrl: process.env.REACT_APP_SOURCE_URL,
  //       eventQueue: [],
  //       lastActivityTime: Date.now(),
  //       inactivityTimeout: null,
  //       stopFn: null,
  //       eventInterval: null,
  //
  //       setCookie(name, value, days) {
  //         let expires = '';
  //         if (days) {
  //           const date = new Date();
  //           date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  //           expires = '; expires=' + date.toUTCString();
  //         }
  //         document.cookie = name + '=' + (value || '') + expires + '; path=/';
  //       },
  //
  //       getCookie(name) {
  //         const nameEQ = name + '=';
  //         const ca = document.cookie.split(';');
  //         for (let i = 0; i < ca.length; i++) {
  //           let c = ca[i];
  //           while (c.charAt(0) === ' ') c = c.substring(1, c.length);
  //           if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  //         }
  //         return null;
  //       },
  //
  //       async fetchUserId() {
  //         const userAgent = navigator.userAgent;
  //         const response = await fetch(window._clientcues.envPublicUrl + 'screen_record/o01-00000C1O/settings', {
  //           method: 'POST',
  //           headers: { 'Content-Type': 'application/json' },
  //           body: JSON.stringify({ first_url: window.location.href, user_agent: userAgent }),
  //         });
  //         if (response.ok) {
  //           const data = await response.json();
  //           return data.userid;
  //         } else {
  //           console.error('Failed to fetch user ID');
  //           return null;
  //         }
  //       },
  //
  //       async initializeUserId() {
  //         let userid = window._clientcues.getCookie('clientcues_userid');
  //         if (!userid) {
  //           userid = await window._clientcues.fetchUserId();
  //           if (userid) {
  //             window._clientcues.setCookie('clientcues_userid', userid, 20 * 365);
  //           }
  //         }
  //         window._clientcues.userid = userid;
  //         return userid;
  //       },
  //
  //       updateUserId(newUserId) {
  //         if (newUserId) {
  //           window._clientcues.setCookie('userid', newUserId, 1);
  //           console.log(`User ID updated to: ${newUserId}`);
  //         } else {
  //           console.error('Invalid User ID');
  //         }
  //       },
  //
  //
  //       handleInactivity() {
  //         if (Date.now() - window._clientcues.lastActivityTime >= window._clientcues.INACTIVITY_THRESHOLD) {
  //           console.log('User inactive for too long, restarting session');
  //           window._clientcues.restartRecording();
  //         }
  //         window._clientcues.inactivityTimeout = setTimeout(window._clientcues.handleInactivity, window._clientcues.INACTIVITY_THRESHOLD);
  //       },
  //
  //       resetInactivityTimeout() {
  //         window._clientcues.lastActivityTime = Date.now();
  //         if (window._clientcues.inactivityTimeout) {
  //           clearTimeout(window._clientcues.inactivityTimeout);
  //         }
  //         window._clientcues.inactivityTimeout = setTimeout(window._clientcues.handleInactivity, window._clientcues.INACTIVITY_THRESHOLD);
  //       },
  //
  //       stopRecording() {
  //         if (window._clientcues.stopFn) {
  //           window._clientcues.stopFn();
  //           window._clientcues.stopFn = null;
  //         }
  //       },
  //
  //       startRecording() {
  //         window._clientcues.stopRecording();
  //         window._clientcues.initializeUserId().then(userid => {
  //           if (!userid) {
  //             console.error('Failed to initialize user ID');
  //             return;
  //           }
  //           window._clientcues.eventQueue = []; // Clear previous session's events
  //           window._clientcues.eventQueue.push({type: 999});
  //           window._clientcues.stopFn = record({
  //             emit(event) {
  //               window._clientcues.eventQueue.push({ ...event });
  //               window._clientcues.resetInactivityTimeout();
  //               if (window._clientcues.eventInterval === null) {
  //                 window._clientcues.eventInterval = setInterval(window._clientcues.sendEvents, 5000);
  //               }
  //             },
  //           });
  //           window._clientcues.resetInactivityTimeout(); // Start/reset inactivity timeout check
  //         });
  //       },
  //
  //       restartRecording() {
  //         console.log('Restarting recording...');
  //         window._clientcues.stopRecording();
  //         window._clientcues.startRecording();
  //       },
  //
  //       sendEvents() {
  //         const currentUserId = window._clientcues.userid;
  //         if (window._clientcues.eventQueue.length > 0 && currentUserId) {
  //           const eventsToSend = [...window._clientcues.eventQueue];
  //           window._clientcues.eventQueue = [];
  //           fetch(window._clientcues.envPublicUrl + 'screen_record/o01-00000C1O/add_events', {
  //             method: 'POST',
  //             headers: { 'Content-Type': 'application/json' },
  //             body: JSON.stringify({ events: eventsToSend, userid: currentUserId, user_agent: navigator.userAgent }),
  //           }).catch(console.error);
  //         }
  //         if (window._clientcues.eventQueue.length === 0 && window._clientcues.eventInterval !== null) {
  //           clearInterval(window._clientcues.eventInterval);
  //           window._clientcues.eventInterval = null;
  //         }
  //       },
  //     };
  //
  //     window._clientcues.startRecording();
  //   }
  //
  //   return () => {
  //     if (window._clientcues.inactivityTimeout) {
  //       clearTimeout(window._clientcues.inactivityTimeout);
  //     }
  //     window._clientcues.stopRecording();
  //   };
  // }, [isAuthenticated]);


  // useEffect(() => {
  //    const cues = new ScreenRecorderExt('o01-00000C1O'); // Pass orgId directly
  //    cues.startRecording();
  //
  //    return () => {
  //      cues.stopRecording();
  //      if (cues.inactivityTimeout) {
  //        clearTimeout(cues.inactivityTimeout);
  //      }
  //    };
  //  }, []);
  useEffect(() => {
    if (isAuthenticated) {
      loadFullCss();
    } else {
      loadMinimalCss();
    }
  }, [isAuthenticated]);
  return (
    <NavigationProvider isAuthenticated={isAuthenticated}>
      <AccountProvider isAuthenticated={isAuthenticated}>
        <BrowserRouter basename={'/'}>
          <GAListener>
            <RouteTable />
          </GAListener>
        </BrowserRouter>
      </AccountProvider>
    </NavigationProvider>
  );
}

export default App;