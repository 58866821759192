import React from 'react';
import styled from 'styled-components';
import { Col, Row, Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import screenRecordingMp4 from '../../../../assets/img/homepage/screen_record/record_example_shorter.mp4';
import { Tab, Tabs } from 'react-bootstrap';
import ZendeskLogo from '../../../../assets/img/companies/logos/zendesk.png';
import IntercomLogo from '../../../../assets/img/companies/logos/intercom.png';
import SalesforceLogo from '../../../../assets/img/companies/logos/salesforce.png';
import SampleSurveyResult from '../../../../assets/img/reporting/sample_survey_result.png';
// import LazyLoad from 'react-lazyload'; // Import LazyLoad

const pieChartTopics = process.env.PUBLIC_URL + '/assets/img/homepage/pie_chart_topics.png';
const roiActions = process.env.PUBLIC_URL + '/assets/img/homepage/roi_actions.png';
const feedbackTrendImage = process.env.PUBLIC_URL + '/assets/img/homepage/bar_two_top_left/feedback_trend.png';
const reasonsForChurn = process.env.PUBLIC_URL + '/assets/img/homepage/bar_two_top_left/reasons_for_churn.png';
const customerSuggestion = process.env.PUBLIC_URL + '/assets/img/homepage/bar_two_top_left/customer_suggestion.png';
const topicDistribution = process.env.PUBLIC_URL + '/assets/img/homepage/bar_right/topic_distribution.png';
const featureImpact = process.env.PUBLIC_URL + '/assets/img/homepage/bar_right/feature_impact.png';
const roadmapImage = process.env.PUBLIC_URL + '/assets/img/homepage/center/board_planning2.png';

const Section = styled.section`
  margin-top: 20px;
  @media (max-width: 760px) {
    margin-top: 10px;
  }
`;

const Title = styled.h1`
  color: rgb(0, 12, 44);
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: -2px;
  word-spacing: 0;
  @media (max-width: 760px) {
    font-size: 28px;
  }
`;

const Description = styled.p`
  color: #666;
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  @media (max-width: 760px) {
    font-size: 16px;
    margin-top: 10px;
  }
`;

const FreePlanButton = styled.button`
  background-color: #0071e1;
  color: white;
  border: 1px solid #0071e1;

  &:hover {
    background-color: #005cbf;
    border-color: #005cbf;
  }

  @media (max-width: 760px) {
    font-size: 14px;
  }
`;

const ImageCard = styled.div`
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin: 0;
  max-width: 150px;
  padding: 0;
  @media (max-width: 1418px) {
    max-width: 250px;
    margin: 10px 0;
    width: 100%;
    max-width: 100%;
  }
`;

const ImageCardWider = styled.div`
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin-top: 12px;
  width: 100%;
  @media (max-width: 1418px) {
    max-width: 600px;
  }
`;

const RoundedImage = styled.img`
  border-radius: 8px;
  width: 100%;
  height: auto;
`;


const DetailedParagraph = styled.p`
  max-width: 400px;
  margin: 20px auto;
  @media (max-width: 768px) {
    max-width: 95%;
  }
`;

const IntegrationsContainer = styled.div`
  justify-content: space-around;
  margin-bottom: 20px;
`;

const PlatformLogo = styled.img`
  width: 40px;
  height: auto;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
`;

const LargeScreenLayout = styled.div`
  display: none;
  @media (min-width: 1419px) {
    display: inherit;
  }
`;

const SmallScreenLayout = styled.div`
  display: block;
  @media (min-width: 1419px) {
    display: none;
  }
`;
const FeaturesSection = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
`;


const FeatureText = styled(DetailedParagraph)`
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
`;


const StyledTabs = styled(Tabs)`
  .nav-item {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: ${props => props.maxWidth || '300px'};

    .nav-link {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      white-space: normal !important;
      width: 100%;
      color: #aaa !important;

      &.active {
        color: black !important;
        background-color: #fff !important;
      }
    }

    .nav-link {
      color: #aaa !important;

      &.active {
        color: black !important;
        background-color: #fff !important;
      }
    }
`;

const NPSSurveyAnalysisLight = () => {
  const history = useHistory();

  const handleFreePlanClick = () => {
    history.push('/signup');
  };

  return (
    <Section>
      <Title style={{ marginBottom: 0 }}>
        Record Sessions, Understand Customers, and Create Roadmaps
      </Title>
      <Title style={{ marginBottom: 0 }}>
        <span style={{ color: 'rgb(0, 113, 225)' }}>Seamlessly</span> with ClientCues
      </Title>
      <Description style={{ marginTop: '4px' }}>
        Maximize your customer satisfaction, streamline the user journey, and improve KPIs. <span
        style={{ color: 'rgb(123, 1, 247)', marginTop: '3px' }}>Powered by AI</span>
      </Description>
      <Description>
        <FreePlanButton onClick={handleFreePlanClick} style={{ borderRadius: '7px', marginLeft: '20px' }}>
          Get Started
        </FreePlanButton>
      </Description>
      <FeaturesSection>
        <Section>
          <Description>Unlock deep insights with session replay and AI-driven analysis.</Description>
          <StyledTabs defaultActiveKey='recordCollect' id='uncontrolled-tab-example' className='mb-3'>
            <Tab eventKey='recordCollect' title='Record & Collect'>
              <Row className='justify-content-md-center'>
                <Col md={8}>
                  <FeatureText>Session Replay</FeatureText>
                  <DetailedParagraph>Capture and replay user sessions to uncover usability issues and optimize user
                    experience.</DetailedParagraph>
                  <video controls autoPlay loop muted style={{ width: '80%', height: 'auto' }}>
                    <source src={screenRecordingMp4} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                  ses
                </Col>

              </Row>
            </Tab>
            <Tab eventKey='insightsAnalysis' title='Insights & Analysis'>
              <Row>
                <Col md={6}>
                  <FeatureText>Identify Key Issues</FeatureText>
                  <DetailedParagraph>Analyze common user issues to prioritize impactful
                    improvements.</DetailedParagraph>
                  <div style={{ fontSize: '10px' }}>
                    {/*<PieChart width={300} height={300}>*/}
                    {/*  <Pie outerRadius={70} data={issuesData} cx={150} cy={150} fill='#8884d8' dataKey='value'*/}
                    {/*       label={(entry) => entry.name}>*/}
                    {/*    {issuesData.map((entry, index) => (*/}
                    {/*      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />*/}
                    {/*    ))}*/}
                    {/*  </Pie>*/}
                    {/*  <Tooltip />*/}
                    {/*</PieChart>*/}
                    {/*<ImageCard>*/}
                    {/*  <LazyLoad height={180} once>*/}
                    <RoundedImage style={{ height: '180px', maxWidth: '300px' }} src={pieChartTopics}
                                  alt='Feedback Trend'
                                  width='300' height='180' />
                    {/*</LazyLoad>*/}
                    {/*</ImageCard>*/}
                  </div>
                </Col>
                <Col md={6}>
                  {/*<BarChart width={500} height={300} data={improvementData} layout='vertical'*/}
                  {/*          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>*/}
                  {/*  <XAxis type='number' />*/}
                  {/*  <YAxis type='category' dataKey='name' width={150} />*/}
                  {/*  <Tooltip />*/}
                  {/*  <Legend />*/}
                  {/*  <CartesianGrid strokeDasharray='3 3' />*/}
                  {/*  <Bar dataKey='value' fill='#8884d8' barSize={20}>*/}
                  {/*    <LabelList dataKey='description' position='right' />*/}
                  {/*  </Bar>*/}
                  {/*</BarChart>*/}
                  {/*    <LazyLoad height={240} once>*/}
                  <RoundedImage style={{ height: '240px', maxWidth: '370px' }} src={roiActions} alt='Feedback Trend'
                                width='370' height='180' />
                  {/*</LazyLoad>*/}
                  <FeatureText>Data-Driven Improvements</FeatureText>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey='actionRoadmap' title='Actionable Roadmaps'>
              <Row>
                <Col md={4}>
                  <FeatureText>Build Roadmaps</FeatureText>
                  <DetailedParagraph>Create prioritized action plans to address insights from session
                    replays.</DetailedParagraph>
                </Col>
                <Col md={8}>
                  <RoundedImage src={roadmapImage} alt='Roadmap' />
                </Col>
              </Row>
            </Tab>
            <Tab eventKey='integrate' title='Integrate data sources'>
              <Row className='justify-content-md-center'>

                <Col md={5}>
                  <Row>
                    <Col>
                      <FeatureText>Platform Integrations</FeatureText>
                      <DetailedParagraph>Seamlessly integrate with platforms like Zendesk, Intercom, and
                        Salesforce.</DetailedParagraph>
                      <IntegrationsContainer>
                        <PlatformLogo src={ZendeskLogo} alt='Zendesk logo' />
                        <PlatformLogo src={IntercomLogo} alt='Intercom logo' />
                        <PlatformLogo src={SalesforceLogo} alt='Salesforce logo' />
                      </IntegrationsContainer>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FeatureText>Feedback Upload</FeatureText>
                      <img src={SampleSurveyResult} alt='survey result' style={{ width: '80%', height: 'auto' }} />
                      <DetailedParagraph>Upload your own feedback data for a comprehensive
                        analysis.</DetailedParagraph>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Tab>
          </StyledTabs>
        </Section>
      </FeaturesSection>
      <div style={{ minHeight: '100px' }}></div>
      <LargeScreenLayout>

        <Row className='justify-content-md-center' style={{ marginTop: '50px' }}>
          <Col style={{ maxWidth: '280px' }}>
            <Row>
              <ImageCard>
                {/*<LazyLoad height={180} once>*/}
                <RoundedImage style={{ height: '180px' }} src={feedbackTrendImage} alt='Feedback Trend' width='300'
                              height='180' />
                {/*</LazyLoad>*/}
              </ImageCard>
              <ImageCard>
                {/*<LazyLoad height={180} once>*/}
                <RoundedImage style={{ height: '180px' }} src={reasonsForChurn} alt='Reasons for Churn' width='300'
                              height='180' />
                {/*</LazyLoad>*/}
              </ImageCard>
            </Row>
            <Row>
              <ImageCardWider>
                {/*<LazyLoad height={180} once>*/}
                <RoundedImage style={{ maxWidth: '255px' }} src={customerSuggestion} alt='Customer Suggestion'
                              width='255' height='180' />
                {/*</LazyLoad>*/}
              </ImageCardWider>
            </Row>
          </Col>
          <Col style={{ maxWidth: '800px', padding: 0, marginLeft: 0 }}>
            <Row> <DetailedParagraph style={{ marginTop: 0 }}>Create prioritized action plans to address insights from
              session
              replays.</DetailedParagraph></Row>
            <Row style={{ marginLeft: '10px' }}>
              <ImageCardWider>
                {/*<LazyLoad height={180} once>*/}
                <RoundedImage src={roadmapImage} alt='Roadmap' width='800' height='600' />
                {/*</LazyLoad>*/}
              </ImageCardWider>
            </Row>
          </Col>
          <Col style={{ maxWidth: '300px', marginLeft: '10px' }}>
            <Row style={{ margin: 0 }}>
              <ImageCardWider>
                {/*<LazyLoad height={180} once>*/}
                <RoundedImage style={{ width: '255px' }} src={topicDistribution} alt='Topic Distribution' width='300'
                              height='180' />
                {/*</LazyLoad>*/}
              </ImageCardWider>
            </Row>
            <Row style={{ marginLeft: 0, marginTop: '30px', marginRight: 0, marginBottom: 0 }}>
              <ImageCardWider>
                {/*<LazyLoad height={180} once>*/}
                <RoundedImage style={{ width: '255px' }} src={featureImpact} alt='Feature Impact' width='300'
                              height='180' />
                {/*</LazyLoad>*/}
              </ImageCardWider>
            </Row>
          </Col>
        </Row>
      </LargeScreenLayout>
      <SmallScreenLayout>
        <Row>
          <Col>
            <FeatureText>Build Roadmaps</FeatureText>
            <DetailedParagraph>Create prioritized action plans to address insights from session
              replays.</DetailedParagraph>
          </Col>

        </Row>
        <Container style={{ marginTop: '50px' }}>
          <Row className='justify-content-md-center'>
            <Col xs={12}>
              <Row className='justify-content-md-center'>
                <Col style={{ maxWidth: '250px' }}>
                  <ImageCard>
                    {/*<LazyLoad height={180} once>*/}
                    <RoundedImage src={feedbackTrendImage} alt='Feedback Trend' width='250' height='150' />
                    {/*</LazyLoad>*/}
                  </ImageCard>
                </Col>
                <Col style={{ maxWidth: '250px' }}>
                  <Row>
                    <ImageCard>
                      {/*<LazyLoad height={180} once>*/}
                      <RoundedImage src={reasonsForChurn} alt='Reasons for Churn' width='250' height='150' />
                      {/*</LazyLoad>*/}
                    </ImageCard>
                  </Row>
                </Col>
              </Row>
              <Row className='justify-content-md-center'>
                <ImageCardWider>
                  {/*<LazyLoad height={180} once>*/}
                  <RoundedImage src={customerSuggestion} alt='Customer Suggestion' width='250' height='150' />
                  {/*</LazyLoad>*/}
                </ImageCardWider>
              </Row>
            </Col>
            <Col xs={12}>
              <Row> <DetailedParagraph style={{ marginTop: 0 }}>Create prioritized action plans to address insights from
                session
                replays.</DetailedParagraph></Row>
              <Row className='justify-content-md-center'>
                <ImageCardWider>
                  {/*<LazyLoad height={180} once>*/}
                  <RoundedImage src={roadmapImage} alt='Roadmap' width='800' height='600' />
                  {/*</LazyLoad>*/}
                </ImageCardWider>
              </Row>
            </Col>
            <Col xs={12}>
              <Row className='justify-content-md-center'>
                <ImageCardWider>
                  {/*<LazyLoad height={180} once>*/}
                  <RoundedImage src={topicDistribution} alt='Topic Distribution' width='250' height='150' />
                  {/*</LazyLoad>*/}
                </ImageCardWider>
              </Row>
              <Row className='justify-content-md-center'>
                <ImageCardWider>
                  {/*<LazyLoad height={180} once>*/}
                  <RoundedImage src={featureImpact} alt='Feature Impact' width='250' height='150' />
                  {/*</LazyLoad>*/}
                </ImageCardWider>
              </Row>
            </Col>
          </Row>
        </Container>
      </SmallScreenLayout>
    </Section>
  );
};

export default NPSSurveyAnalysisLight;