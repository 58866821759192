/* eslint camelcase: 0 */

import axios from 'axios';
import {base_url,tokenConfig } from './common';




export function get_user_preference_left_menu(){
    return axios.get(base_url+'user_preference/get_user_preference_left_menu', tokenConfig());
}







