import React, { lazy } from 'react';

import {

  MdFolderOpen,
  MdOutlineUploadFile,
  MdReplay, MdIntegrationInstructions,
} from 'react-icons/md';

import { HiOutlineDocumentReport } from 'react-icons/hi';
import { PiFolderFill } from 'react-icons/pi';



// import MasterDetail from "../../../grid/tableGrid";

const ReportingPage = lazy(() => import('../../../../../pages/private/reporting/ReportingPage'));
const UploadSurveyDataPage = lazy(() => import('../../../../../pages/private/reporting/UploadSurveyDataPage'));
const SessionPlaylistPage = lazy(() => import('../../../../../pages/private/screen_record/SessionPlaylistPage'));
const SessionRecordScriptPage = lazy(() => import('../../../../../pages/private/screen_record/SessionRecordScriptPage'));
const KanbanPage = lazy(() => import('../../../../../pages/private/roadmap/KanbanPage'));


const FolderIcon = MdFolderOpen;

interface Dictionary<T> {
  [Key: string]: T;
}

export interface Category {
  name: string;
  id?: string;
  path?: string;
  icon?: JSX.Element;
  title?: boolean;
  folder?: boolean;
  child?: boolean;
  sticky?: boolean;
  badge?: boolean;
  badgeNumber?: Number;
  children?: Category[];
  divider?: boolean;
  component?: Function;
  params?: Dictionary<string>;
  canHaveSubcategories?: boolean;
}

const iconStyle = {
  color: 'white',
  stroke: 'rgb(65, 65, 65)',
  strokeWidth: '2',
};

// export const categories: Category[] = [
//   {
//     name: '', children: [{ name: 'Home', icon: <MdHome /> },
//       { name: 'Insights', icon: <MdInsights /> },
//       { name: 'Portal', icon: <MdWork /> }, // Placeholder icon
//       { name: 'Reports', icon: <MdReport />, badge: true, badgeNumber: 6 },
//       { name: 'All Teamspaces', icon: <MdWork /> }],
//   }, // Placeholder icon
//   { name: 'Favorites', title: true },
//   {
//     name: '', children: [{ name: 'Notes assigned to me', icon: <MdEvent /> },
//       { name: 'Followed notes', icon: <MdFavorite /> }],
//   },
//   { name: 'Teamspaces', title: true },
//   {
//     name: '', children: [{
//       name: 'General', child: true, icon: <FolderIcon />,
//       children: [
//         {
//           name: 'Insights Boards', folder: true, icon: <FolderIcon />,
//           children: [
//             { name: 'Unassigned notes' },
//             { name: 'Unprocessed notes' },
//             { name: 'All notes' },
//             // Other sub-sub-categories...
//           ],
//         },
//         // Other sub-categories under "General"...
//       ],
//     }],
//   },
//   // Extending with additional main categories
//   {
//     name: 'PERSONAL', title: true,
//   },
//   {
//     name: '', children: [{
//       name: 'Insights Boards', folder: true, icon: <FolderIcon />,
//       children: [
//         { name: 'Notes assigned to me', icon: <MdEvent /> },
//         { name: 'Followed notes', icon: <MdFavorite /> },
//       ],
//     }],
//   },
//   // Other categories as necessary...
//   {
//     name: 'ACCOUNT', title: true,
//   },
//   {
//     name: '', children: [{
//       name: 'Account Summary', icon: <MdBook />,
//     },
//       {
//         name: 'Settings', icon: <MdBuild />,
//       },
//       {
//         name: 'User Management', icon: <FolderIcon />, children: [
//           { name: 'Users', icon: <MdBook /> },
//           { name: 'Teams', icon: <MdWork /> },
//           // Other sub-categories...
//         ],
//       }],
//   },
//   // Sticky categories demonstration
//   {
//     name: 'Sticky Categories', sticky: true, children: [
//       { name: '6 days left buy now' },
//       { name: 'Try different plan' },
//     ],
//   },
// ];

export const categories: Category[] = [
  { name: 'Survey', title: true },
  {
    name: '', path: 'survey', id: 'survey', children: [{
      name: 'Upload Survey data',
      path: 'upload_survey_data',
      component: UploadSurveyDataPage,
      icon: <MdOutlineUploadFile />,
    },
      {
        name: 'Reports', id: 'reporting', path: 'reporting', icon: <HiOutlineDocumentReport />, children: [
          { name: 'AI Generated', id: 'ai-generated', path: 'AI', component: ReportingPage, icon: <PiFolderFill /> },

          // Other sub-categories...
        ],
      },
    ],
  },
     { name: 'Session Replay', title: true },
  {
    name: '', path: 'session_replay', id: 'session_replay', children: [
      {
        name: 'Setup recording',
        path: 'setup',
        component: SessionRecordScriptPage,
        icon: <MdIntegrationInstructions  />,

      },
      {
        name: 'Watch users sessions',
        path: 'replay',
        component: SessionPlaylistPage,
        icon: <MdReplay />,
      },
    ],
  },
  { name: 'Roadmap planning', title: true },
  {
    name: '', path: 'roadmap', id: 'roadmap', children: [
      {
        name: 'Roadmaps',
        id: 'roadmaps',
        path: 'roadmaps',
        component: KanbanPage,
        icon: <PiFolderFill />,
        canHaveSubcategories: true,
        children: [
          // { name: 'Kanban sample', id: 'kanban', path: 'kanban', component: KanbanPage, icon: <MdOutlineTimeline   />  },
          //  { name: 'Table sample', id: 'table', path: 'table', component: TablePage, icon: <MdOutlineTableChart   />  },
          // Other sub-categories...
        ],

      },
    ],
  },
  //session replay:
  // {
  //   name: 'Session Replay', title: true,
  // },

  //   { name: 'Analytics', title: true },
  // {
  //   name: '', path: 'analytics', id: 'analytics', children: [
  //     {
  //       name: 'topic breakdown',
  //       path: 'topic-breakdown',
  //       component: MasterDetail,
  //       icon: <MdIntegrationInstructions  />,
  //
  //     },
  //
  //   ],
  // },


];