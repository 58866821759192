export const tokenConfig = (extra_headers) => ({
    headers: {
        'Authorization': localStorage.getItem('token'),
        ...extra_headers,
    },
});


export const base_url = process.env.REACT_APP_SOURCE_URL 

