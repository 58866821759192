/* eslint camelcase: 0 */

import axios from 'axios';
import { base_url, tokenConfig } from './common';


export function submit_feedback(contactReason, contactDetails) {
  return axios.post(base_url + 'user/submit_feedback', {
    'contactReason': contactReason,
    'contactDetails': contactDetails,
  }, tokenConfig());
}

export function submit_public_feedback(email, contactReason, contactDetails) {
  return axios.post(base_url + 'user/submit_public_feedback', {
    'email': email,
    'contactReason': contactReason,
    'contactDetails': contactDetails,
  });
}

